import { updateTbEnrollmentIsOnline, resetPlayerScore } from '@/http/api'
export default {
  data () {
    return {
      currentMenuTag: '',
      racingToolTitle: '',
      racingToolList: [
        // {
        //   leftImg: require('@/assets/img/icon-shijuanguanli.png'),
        //   rightTitle: '比赛形式管理',
        //   rightDesc: '修改各赛段比赛形式',
        //   icon: 'icon-bisai',
        //   flag: '',
        //   menuTag: '1'
        // },
        {
          rightTitle: '表单答题解锁码',
          rightDesc: '查看/生成表单答题解锁码',
          icon: 'icon-Unlock',
          flag: this.$chnEngStatusCode.answer_player_code,
          path: '/schedule_competitions/schedule_formcode_competition',
          menuTag: '2'
        },
        {
          rightTitle: '表单答题管理',
          rightDesc: '查看/导出表单答题成绩',
          icon: 'icon-shijuan',
          flag: this.$chnEngStatusCode.answer_player_form,
          path: '/schedule_competitions/schedule_formanswer_competition',
          menuTag: '3'
        },
        {
          rightTitle: '重置选手成绩',
          rightDesc: '重置选手线上初/复赛成绩',
          icon: 'icon-bg-reset',
          dialogTitle: '重置选手成绩',
          flag: this.$chnEngStatusCode.reset_player_score,
          menuTag: '4'
        },
        {
          rightTitle: '参赛方式管理',
          rightDesc: '修改用户参赛方式',
          dialogTitle: '修改参赛方式',
          flag: this.$chnEngStatusCode.change_player_gametype,
          icon: 'icon-qiehuanmoshi',
          menuTag: '5'
        },
        {
          rightTitle: '免初赛管理',
          rightDesc: '设置选手免初赛',
          dialogTitle: '免初赛管理',
          icon: 'icon-qiehuanmoshi',
          flag: this.$chnEngStatusCode.exempt_player_game,
          path: '/schedule_competitions/schedule_dispense_competition',
          menuTag: '6'
        }
      ],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      gameTypeChangePoster: false,
      editGameTypeForm: {
        phone: '',
        remark: '',
        isOnline: '',
        raceSchedule: ''
      },
      editGameTypeFormRules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        raceSchedule: [{ required: true, message: '请选择比赛类型', trigger: 'change' }],
        isOnline: [{ required: true, message: '请选择成绩类型', trigger: 'change' }],
        remark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }]
      },
      scoreFlagList: [
        {
          dictCode: 1,
          dictLabel: '初赛',
          dictValue: 1,
          variable: true
        },
        {
          dictCode: 2,
          dictLabel: '复赛',
          dictValue: 2,
          variable: false
        }
      ],
      scoreTypeList: [
        {
          dictCode: 1,
          dictLabel: '线上',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '线下',
          dictValue: 0
        }
      ],
      scheduleCardList: [],
      indivScoreStatisticsList: [
        {
          scheduleName: '初赛参赛人数',
          icon: 'icon-saishi',
          scheduleId: '1',
          promotionNum: 0,
          historyNum: 0,
          growth: '+45%'
        },
        {
          scheduleName: '初赛晋级人数',
          icon: 'icon-tisheng',
          scheduleId: '2',
          promotionNum: 0,
          historyNum: 0,
          growth: '+45%'
        },
        {
          scheduleName: '复赛参赛人数',
          icon: 'icon-saishi',
          scheduleId: '1',
          promotionNum: 0,
          historyNum: 0,
          growth: '+45%'
        },
        {
          scheduleName: '复赛晋级人数',
          icon: 'icon-tisheng',
          scheduleId: '1',
          promotionNum: 0,
          historyNum: 0,
          growth: '+45%'
        }
      ]
    }
  },
  created () {
    this.getRaceScheduleList()
  },
  methods: {
    getRaceScheduleList () {
      this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(data => {
        console.log('res____', data)
        var scheduleList = data.map(item => {
          item.authorityFlag = 'GAME_' + item.scheduleFlag
          return item
        })
        console.log('scheduleList', scheduleList)
        this.scheduleCardList = scheduleList
      })
    },
    // 考务工具点击
    racingToolClicked (item) {
      console.log('item', item)
      this.currentMenuTag = item.menuTag
      this.racingToolTitle = item.dialogTitle
      switch (item.flag) {
        case 'reset_player_score': // 重置选手成绩
          this.gameTypeChangePoster = true
          break
        case 'change_player_gametype': // 参赛方式管理
          this.gameTypeChangePoster = true
          break
        default:
          this.$router.push(
            {
              path: item.path
            }
          )
          break
      }
    },
    // 赛程成绩
    scheduleCardTap (scheduleData) {
      // if (scheduleData.scheduleFlag === '' || scheduleData.scheduleFlag === '') {
      this.$router.push(
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_competition',
          query: {
            scheduleFlag: scheduleData.scheduleFlag,
            raceSchedule: scheduleData.id
          }
        }
      )
      // } else {
      //   this.$router.push(
      //     {
      //       path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_scoreList',
      //       query: {
      //         scheduleFlag: scheduleData.scheduleFlag,
      //         raceSchedule: scheduleData.id
      //       }
      //     }
      //   )
      // }
    },
    // 修改参赛形式
    editGameTypeScore () {
      this.$refs.editGameTypeFormRef.validate(async valid => {
        if (!valid) return
        if (this.currentMenuTag === '4') {
          const data = {
            activityType: this.$chnEngStatusCode.activityType,
            phone: this.editGameTypeForm.phone,
            raceSchedule: this.editGameTypeForm.raceSchedule,
            reason: this.editGameTypeForm.remark
          }
          resetPlayerScore(data).then((res) => {
            this.gameTypeChangePoster = false
            return this.$message.success('修改成功')
          }).catch((err) => {
            console.log('err', err)
          })
        } else if (this.currentMenuTag === '5') {
          updateTbEnrollmentIsOnline(this.editGameTypeForm).then((res) => {
            this.gameTypeChangePoster = false
            return this.$message.success('修改成功')
          }).catch((err) => {
            console.log('err', err)
          })
        }
      })
    },
    // 修改参赛方式关闭
    editGameTypeDialogClose () {
      this.$refs.editGameTypeFormRef.resetFields()
    },
    getData () {
    }
  }
}
